/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { account_type, Duration, Frequency, Gender, ProductType } from "../../utils";
import { AppDispatch, RootState } from "../../redux/store";
import {
	_CREATE_E_CARD,
	_CREATE_INDIVIDUAL_E_CARD,
	_UPDATE_INDIVIDUAL_E_CARD,
} from "../../redux/e-card/e_cardAction";
import { toastify } from "../toastify/toastify";
import RadioButton from "../../pages/business-details/components/radio-button/radio-button";
import { InputField } from "./input-field";
import { Button } from "./button";
import SectionTitle from "../../pages/create-ecard/section-title/section-title";
import { TopNavigation } from "./top-navigation";
import { SelectField } from "./select-field";
import { useNavigation } from "../../contexts";
import { Screens } from "../../enums";

type BaseValues = {
	product_type: string;
	amount: string;
	frequency: string;
	cardName: string;
	duration?: string;
};

export const CreateEcardForm = () => {
	const { setCurrentScreen, setNotificationState, eCardDetails } = useNavigation();
	const dispatch = useDispatch<AppDispatch>();
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user.userType);

	const [isNewContributor, setIsNewContributor] = useState<boolean>(true);
	const { loading, contributors } = useSelector((state: RootState) => state.e_card);
	const [selectedProduct, setSelectedProduct] = useState<"fixed" | "flexible">(
		isMerchant ? "flexible" : "fixed",
	);

	const baseValues: BaseValues = {
		product_type: !isMerchant ? "fixed" : "",
		amount: eCardDetails ? String(eCardDetails?.product?.minimum_amount) : "",
		frequency: eCardDetails ? eCardDetails?.product?.minimum_frequency : "",
		cardName: eCardDetails ? eCardDetails.ecard_name : "",
		...(selectedProduct === "fixed" && {
			duration: eCardDetails ? eCardDetails?.product?.minimum_duration : "",
		}),
	};

	const baseSchema = {
		product_type: Yup.string().required("This is a required field"),
		amount: Yup.string().required("This is a required field"),
		frequency: Yup.string().required("This is a required field"),
		cardName: Yup.string().required("This is a required field"),
		...(selectedProduct === "fixed" && {
			duration: Yup.string().required("This is a required field"),
		}),
	};

	const initialValues = isMerchant
		? {
				...baseValues,
				first_name: "",
				last_name: "",
				phone_number: "",
				residential_address: "",
				gender: "",
			}
		: baseValues;

	const validationSchema = Yup.object().shape(
		isMerchant
			? {
					...baseSchema,
					first_name: Yup.string().required("This is a required field"),
					last_name: Yup.string().required("This is a required field"),
					phone_number: Yup.string().required("This is a required field"),
					residential_address: Yup.string(),
					gender: Yup.string(),
				}
			: baseSchema,
	);

	const handleSubmit = async (values: BaseValues, { setErrors }: any) => {
		const errors = {};
		if (Object.entries(errors).length === 0) {
			try {
				await (isMerchant
					? dispatch(
							_CREATE_E_CARD({
								...values,
								setCurrentScreen,
								setNotificationState,
							}),
						).unwrap()
					: eCardDetails
						? dispatch(
								_UPDATE_INDIVIDUAL_E_CARD({
									...values,
									ecard_id: eCardDetails?.ecard_id,
									setCurrentScreen,
									setNotificationState,
								}),
							).unwrap()
						: dispatch(
								_CREATE_INDIVIDUAL_E_CARD({
									...values,
									setCurrentScreen,
									setNotificationState,
								}),
							).unwrap());
			} catch (err: any) {
				toastify("error", err.message);
			}
		} else {
			setErrors(errors);
		}
	};

	function getNameByIdentifier(dataArray: Array<any>, identifier: string) {
		const item = dataArray.find(element => element.identifier === identifier);
		return item ? item.name : null;
	}

	const durationName = getNameByIdentifier(
		Duration,
		eCardDetails ? eCardDetails?.product?.minimum_duration : "",
	);
	const frequencyIdentifier = getNameByIdentifier(
		Frequency,
		eCardDetails ? eCardDetails?.product?.minimum_frequency : "",
	);

	return (
		<div
			className={`flex flex-col gap-10 no-scrollbar ${isMerchant ? "pt-[70px] md:pt-10" : ""}`}
		>
			{isMerchant && (
				<SectionTitle
					className="!text-sm !font-semibold"
					title="Contributor’s Information"
				/>
			)}
			<Formik
				validateOnMount
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ isValid, handleSubmit, setFieldValue, values }) => (
					<Form
						onSubmit={handleSubmit}
						className={`flex flex-col gap-[30px] ${isMerchant ? "pb-20" : ""}`}
					>
						{isMerchant && (
							<>
								<div className="w-full flex gap-10 md:gap-5">
									<RadioButton
										title="New Contributor"
										isActive={isNewContributor}
										onClick={() => {
											setIsNewContributor(true);
											setFieldValue("contributor_id", "");
											setFieldValue("first_name", "");
											setFieldValue("last_name", "");
											setFieldValue("phone_number", "");
											setFieldValue("residential_address", "");
											setFieldValue("gender", "");
										}}
									/>
									<RadioButton
										title="Existing Contributor"
										isActive={!isNewContributor}
										onClick={() => setIsNewContributor(false)}
									/>
								</div>

								{!isNewContributor && (
									<SelectField
										isContributor
										data={contributors}
										label={"Select Contributor*"}
										placeholder={"eg Doe"}
										setValue={(item: any) => {
											setFieldValue("contributor_id", item?.contributor_id);
											setFieldValue("first_name", item?.first_name);
											setFieldValue("last_name", item?.last_name);
											setFieldValue("phone_number", item?.phone_number);
											setFieldValue(
												"residential_address",
												item?.residential_address,
											);
											setFieldValue(
												"gender",
												item?.gender === 1 ? "male" : "female",
											);
										}}
										setFieldValue={(value: string) =>
											setFieldValue("contributor_name", value)
										}
									/>
								)}

								{isNewContributor && (
									<>
										<div className="w-full flex flex-col md:flex-row gap-[30px] md:gap-5">
											<InputField
												name="first_name"
												label="First name*"
												placeholder="eg John"
												className="md:w-[50%]"
											/>
											<InputField
												name="last_name"
												label="Last name*"
												placeholder="eg John"
												className="md:w-[50%]"
											/>
										</div>
										<div className="w-full flex flex-col md:flex-row gap-[30px] md:gap-5">
											<InputField
												type="tel"
												name="phone_number"
												label="Phone number*"
												placeholder="80 **** *****"
												className="md:w-[50%]"
											/>
											<SelectField
												data={Gender}
												label={"Gender*"}
												placeholder={"Select gender"}
												setFieldValue={(value: string) =>
													setFieldValue("gender", value)
												}
												className="md:w-[50%]"
											/>
										</div>
										<InputField
											type="text"
											name="residential_address"
											label="Residential Address*"
											placeholder="House address, Area, LG, State....."
										/>
									</>
								)}
							</>
						)}

						{isMerchant ? (
							<SectionTitle
								className="!text-sm !font-semibold my-2.5"
								title="e-Card Details"
							/>
						) : (
							<TopNavigation
								onClick={() => setCurrentScreen(Screens.eCardDetails)}
								rightTitle={eCardDetails ? "EDIT e-CARD" : "CREATE e-CARD"}
							/>
						)}

						<InputField
							name="cardName"
							value={values.cardName}
							label={isMerchant ? "Card Name*" : "Name of eCard*"}
							placeholder="Name of eCard"
						/>

						{isMerchant && (
							<div>
								<SelectField
									type="product_type"
									data={ProductType}
									label="Product Type*"
									placeholder="Select type"
									setFieldValue={(value: "fixed" | "flexible" | any) => {
										setFieldValue("product_type", value);
										setSelectedProduct(value);
									}}
								/>
							</div>
						)}

						<InputField
							type="number"
							inputMode="decimal"
							name="amount"
							value={values.amount}
							label={isMerchant ? "Amount*" : "Savings amount*"}
							placeholder="0.00"
						/>

						<div className="w-full flex flex-col md:flex-row gap-[30px] md:gap-5">
							<SelectField
								data={Frequency}
								label={isMerchant ? "Frequency*" : "How often?*"}
								placeholder="Select frequency"
								value={frequencyIdentifier || ""}
								setFieldValue={(value: string) => setFieldValue("frequency", value)}
								className={`w-full ${values.product_type.toLocaleLowerCase() === "fixed" && "md:!w-[50%]"}`}
							/>
							{values.product_type.toLocaleLowerCase() === "fixed" && (
								<SelectField
									data={Duration}
									label={isMerchant ? "Duration*" : "How long?*"}
									placeholder="Select duration"
									setFieldValue={(value: string) =>
										setFieldValue("duration", value)
									}
									className="md:w-[50%]"
									value={durationName || ""}
								/>
							)}
						</div>

						<div className="flex flex-col gap-[25px]">
							<Button
								isValid={isValid}
								type="submit"
								isLoading={loading}
								title={
									isMerchant && isNewContributor
										? "Create contributor"
										: eCardDetails
											? "Update eCard"
											: "Create eCard"
								}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};
