export enum Screens {
	markSavings = "mark-savings",
	createEcard = "create-e-card",
	settlement = "settlement",
	paymentOption = "payment-option",
	walletFunding = "wallet-funding",
	success = "success",
	eCardDetails = "e-card-details",
	deleteBankAccount = "delete-bank-account",
	changePassword = "change-password",
	confirmTransaction = "confirm-transaction",
	emailConfirmation = "email-confirmation",
	error = "error",
	fundingTransactionStatus = "funding-transaction-status",
	resetPin = "reset-pin",
	scxPhoneVerification = "scx-phone-verification",
}
