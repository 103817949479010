/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import CustomModal from "../../components/custom-modal/custom-modal";
import PageTitle from "../../components/page-title/page-title";
import { account_type } from "../../utils";
import PasscodeForm from "../create-passcode/components/passcode-form/passcode-form";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { _RESET_PIN } from "../../redux/customer/customerActions";
import { toastify } from "../../components/toastify/toastify";
import { useNavigation } from "../../contexts";

const ResetPin = () => {
	const accountType = "merchant";
	const dispatch = useDispatch<AppDispatch>();
	const isMerchant = account_type(accountType);
	const { setCurrentScreen, setNotificationState } = useNavigation();

	const handleSubmit = async (data: any) => {
		const { current_pin, pin, confirmPin } = data;
		try {
			await dispatch(
				_RESET_PIN({
					current_pin,
					new_pin: pin,
					confirm_new_pin: confirmPin,
					setNotificationState,
					setCurrentScreen,
				}),
			);
		} catch (error: any) {
			return toastify("error", error.message);
		}
	};

	return (
		<CustomModal className="md:!min-w-[540px]">
			<PageTitle
				title={"Reset Transaction PIN"}
				subText={"Amet minim mollit non deserunt ullamco est sit aliqua dolor."}
			/>

			<PasscodeForm type="reset" isMerchant={isMerchant} handleSubmit={handleSubmit} />
		</CustomModal>
	);
};

export default ResetPin;
