/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Layout from "../../components/layout/layout";
import OnboardTitle from "../../components/onboard-title/onboard-title";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { toastify } from "../../components/toastify/toastify";
import { _CREATE_CUSTOMER } from "../../redux/customer/customerActions";
import { Button, InputField } from "../../components";
import { UrlText } from "../../components/shared/url-text";
import { useNavigation } from "../../contexts";
import { Screens } from "../../enums";

const MerchantAccountSetup = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { loading } = useSelector((state: RootState) => state.customer);
	const { setCurrentScreen, setConfirmTransactionState } = useNavigation();

	return (
		<Layout width={540}>
			<>
				<OnboardTitle
					title={"Create account"}
					subText={"Secure savings, seamless thrift"}
				/>

				<Formik
					validateOnMount
					enableReinitialize
					initialValues={{
						firstname: "",
						lastname: "",
						email_address: "",
						phone_number: "",
						password: "",
						confirm_password: "",
						customer_type: "INDIVIDUAL",
						phone: "",
					}}
					validationSchema={Yup.object({
						firstname: Yup.string().required("This is a required field"),
						lastname: Yup.string().required("This is a required field"),
						phone_number: Yup.string().required("This is a required field"),
						email_address: Yup.string()
							.email("Invalid email address")
							.required("This is a required field"),
						password: Yup.string()
							.matches(
								/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,.\\/;':"-]).{8,}$/,
								"Must At Least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
							)
							.required("This is a required field"),
						confirm_password: Yup.string()
							.required("This is a required field")
							.test("passwords-match", "Passwords must match", function (value) {
								return this.parent.password === value;
							}),
					})}
					onSubmit={async (values, { setErrors }) => {
						const {
							firstname,
							lastname,
							phone_number,
							email_address,
							password,
							confirm_password,
							customer_type,
						} = values;
						const errors = {};
						// {error === null ? errors = {} : errors = error}
						localStorage.setItem("email", values.email_address);
						if (Object.entries(errors).length === 0 && errors.constructor === Object) {
							try {
								const res: any = await dispatch(
									_CREATE_CUSTOMER({
										firstname,
										lastname,
										phone_number,
										email_address,
										password,
										confirm_password,
										customer_type,
									}),
								).unwrap();
								if (res.status) {
									setCurrentScreen(Screens.emailConfirmation);
									setConfirmTransactionState({ type: "verification" });
									return toastify("success", res.message);
								}
							} catch (error: any) {
								return toastify("error", error.message);
							}
						} else {
							setErrors(errors);
						}
					}}
				>
					{({ isValid, handleSubmit, setFieldValue }) => (
						<Form
							onSubmit={handleSubmit}
							className="mt-10 flex flex-col gap-[30px] w-full"
						>
							<div className="w-full flex flex-col  md:flex-row gap-[30px] md:gap-5">
								<InputField
									name="firstname"
									label="First name*"
									placeholder="eg John"
									className="md:w-[50%]"
								/>
								<InputField
									name="lastname"
									label="Last name*"
									placeholder="eg John"
									className="md:w-[50%]"
								/>
							</div>

							<div className="w-full flex flex-col  md:flex-row gap-[30px] md:gap-5">
								<InputField
									type="email"
									name="email_address"
									label="Email Address*"
									placeholder="email@email.com"
									className="md:w-[50%]"
								/>
								<InputField
									type="tel"
									name="phone"
									label="Phone number*"
									placeholder="80 **** *****"
									className="md:w-[50%]"
									setFieldValue={(value: string) =>
										setFieldValue("phone_number", value)
									}
								/>
							</div>

							<div className="w-full flex flex-col  md:flex-row gap-[30px] md:gap-5">
								<InputField
									type="password"
									name="password"
									label="Password*"
									placeholder="Password (8+ characters)"
									className="md:w-[50%]"
								/>
								<InputField
									type="password"
									name="confirm_password"
									label="Confirm Password*"
									placeholder="Password (8+ characters)"
									className="md:w-[50%]"
								/>
							</div>

							<div className="flex flex-col gap-[25px]">
								<Button
									isLoading={loading}
									isValid={isValid}
									type={"submit"}
									title={"Continue"}
								/>

								<UrlText
									text={"Already have an account?"}
									urlText={" Login here"}
									url={"/sign-in"}
								/>
							</div>
						</Form>
					)}
				</Formik>
			</>{" "}
		</Layout>
	);
};

export default MerchantAccountSetup;
