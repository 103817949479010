import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/layout";
import TabItem from "./components/tab-item/tab-item";
import { account_type, groupTransactionsByDate } from "../../utils";
import Transaction from "../../components/transaction/transaction";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { _GET_TRANSACTIONS } from "../../redux/customer/customerActions";
import { IGroupedTransactions, ITransactions } from "../../types";
import { EmptySection, ListCardWrapper, TopNavigation } from "../../components";

const Transactions = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { transactions } = useSelector((state: RootState) => state.customer);
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType?.toLowerCase() || "individual");

	const [currentTab, setCurrentTab] = useState<number>(0);
	const [data, setData] = useState<ITransactions[]>([]);

	const sectionTabs = [
		{
			title: "All",
		},
		{
			title: "Savings",
		},
		{
			title: "Settlements",
		},
	];

	const restructuredData: IGroupedTransactions[] = groupTransactionsByDate(data);

	useEffect(() => {
		dispatch(_GET_TRANSACTIONS());
	}, []);

	useEffect(() => {
		if (currentTab === 1) {
			const savings: ITransactions[] = transactions?.savings;
			setData(savings);
		} else if (currentTab === 2) {
			const withdrawals = transactions?.settlements;
			setData(withdrawals);
		} else {
			const allTx = transactions?.all;
			setData(allTx);
			// setData(data?.data?.all);
			// data
		}
	}, [currentTab]);

	return (
		<Layout>
			{isMerchant && <TopNavigation middleTitle={"Transactions"} />}
			{isMerchant ? (
				<>
					<div
						className={`mt-[30px] flex items-center gap-2.5 ${isMerchant ? "pt-10 md:pt-0 " : "pt-0"}`}
					>
						{sectionTabs.map((item: { title: string }, index: number) => (
							<TabItem
								key={index}
								title={item.title}
								isActive={currentTab === index}
								onClick={() => setCurrentTab(index)}
							/>
						))}
					</div>

					<div className="mt-[30px] flex flex-col gap-[30px] h-[72vh] md:h-[60vh] overflow-y-auto no-scrollbar">
						{restructuredData.length < 1 ? (
							<EmptySection
								text="Recent transactions will show up here"
								className="my-10 md:my-[90px]"
							/>
						) : (
							restructuredData
								.reverse()
								.map((group: IGroupedTransactions, index: number) => (
									<div key={index} className="flex flex-col gap-[30px]">
										<p className="text-grey400 text-xs font-medium">
											{group.date}
										</p>
										{group.transactions.map(
											(item: ITransactions, index: number) => (
												<div
													key={index}
													className="flex flex-col gap-[25px]"
												>
													<Transaction item={item} />
												</div>
											),
										)}
									</div>
								))
						)}
					</div>
				</>
			) : (
				<ListCardWrapper
					activeTabIndex={currentTab}
					setActiveTabIndex={setCurrentTab}
					titleArray={[{ title: "All" }, { title: "Savings" }, { title: "Settlements" }]}
					actionComponent={undefined}
				>
					<div className="pt-[30px] flex flex-col gap-[30px] h-[70vh] md:h-[60vh] overflow-y-auto no-scrollbar">
						{restructuredData.length < 1 ? (
							<EmptySection
								text="Recent transactions will show up here"
								className="my-10 md:my-[90px]"
							/>
						) : (
							restructuredData
								.reverse()
								.map((group: IGroupedTransactions, index: number) => (
									<div key={index} className="flex flex-col gap-[30px]">
										<p className="text-grey400 text-xs font-medium">
											{group.date}
										</p>
										{group.transactions.map(
											(item: ITransactions, index: number) => (
												<div
													key={index}
													className="flex flex-col gap-[25px]"
												>
													<Transaction item={item} />
												</div>
											),
										)}
									</div>
								))
						)}
					</div>
				</ListCardWrapper>
			)}
		</Layout>
	);
};

export default Transactions;
