import React, { FC } from "react";
import { Link } from "react-router-dom";
import { EditIcon, Lock } from "../../../assets/icons";
import { Screens } from "../../../enums";
import { useNavigation } from "../../../contexts";

interface Props {
	url?: string;
	screen?: Screens;
	title: string;
	value: string;
}

const ResetLink: FC<Props> = ({ url, title, value, screen }) => {
	const { setCurrentScreen } = useNavigation();
	return (
		<>
			{url ? (
				<Link
					to={url}
					className="w-full bg-grey9 rounded-lg flex items-center justify-between h-[48px] py-2 px-3"
				>
					<div className="flex items-center gap-2">
						<div className="w-8 h-8 rounded-lg bg-white flex items-center justify-center">
							<img src={Lock} alt="lock" />
						</div>

						<div className="flex flex-col">
							<p className="text-[10px] text-grey400 uppercase">{title}</p>
							<p className="text-[18px] leading-3 font-bold">{value}</p>
						</div>
					</div>

					<div className="flex items-center gap-1">
						<img src={EditIcon} alt="edit-icon" className="w-[14px] h-[14px]" />
						<p className="text-blue text-xs font-semibold">Change</p>
					</div>
				</Link>
			) : (
				<div
					onClick={() => setCurrentScreen(screen || null)}
					className="w-full bg-grey9 rounded-lg flex items-center justify-between h-[48px] py-2 px-3 cursor-pointer"
				>
					<div className="flex items-center gap-2">
						<div className="w-8 h-8 rounded-lg bg-white flex items-center justify-center">
							<img src={Lock} alt="lock" />
						</div>

						<div className="flex flex-col">
							<p className="text-[10px] text-grey400 uppercase">{title}</p>
							<p className="text-[18px] leading-3 font-bold">{value}</p>
						</div>
					</div>

					<div className="flex items-center gap-1">
						<img src={EditIcon} alt="edit-icon" className="w-[14px] h-[14px]" />
						<p className="text-blue text-xs font-semibold">Change</p>
					</div>
				</div>
			)}
		</>
	);
};

export default ResetLink;
