/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-undef */

import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/layout/layout";
import EditMerchantInput from "./component/EditMerchantInput";
import { ListCardWrapper, TopNavigation } from "../../components";
import { useSelector } from "react-redux";
import { account_type } from "../../utils";
import { RootState } from "../../redux/store";
import { useNavigation } from "../../contexts";

const EditMerchant = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const data = state?.data;
	const isEcard = data?.product && true;
	const { setEcardDetails } = useNavigation();
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType?.toLowerCase() || "individual");

	return (
		<>
			<Layout>
				{isMerchant && (
					<TopNavigation
						onClick={() => {
							setEcardDetails(null);
							navigate(-1);
						}}
						middleTitle={isEcard ? "e-Card Details" : "Profile Details"}
					/>
				)}
				<div className="flex justify-center mt-[60px] md:mt-10" />
				{isMerchant ? (
					<EditMerchantInput />
				) : (
					<div className="-mt-14">
						<ListCardWrapper actionComponent={undefined}>
							<EditMerchantInput />
						</ListCardWrapper>
					</div>
				)}
			</Layout>
		</>
	);
};

export default EditMerchant;
