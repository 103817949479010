import React, { useEffect } from "react";
import Layout from "../../components/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { _GET_CONTRIBUTORS, _GET_E_CARDS } from "../../redux/e-card/e_cardAction";
import { account_type } from "../../utils";
import { MarkSavingsForm, TopNavigation } from "../../components";

const MarkSavings = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType?.toLowerCase() || "individual");

	useEffect(() => {
		dispatch(_GET_E_CARDS(user?.userType?.toLowerCase() || "individual"));
		if (isMerchant) {
			dispatch(_GET_CONTRIBUTORS());
		}
	}, [user?.userType]);

	return (
		<>
			{isMerchant ? (
				<Layout>
					<TopNavigation rightTitle="MARK SAVINGS" />
					<MarkSavingsForm />
				</Layout>
			) : (
				<></>
			)}
		</>
	);
};

export default MarkSavings;
