/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { ListCardWrapper } from "./list-card-wrapper";
import SectionTitle from "../../pages/create-ecard/section-title/section-title";
import { Button, EmptySection } from "../shared";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { BusinessDetails, CardIcon, deleteIcon, SecurityIcon } from "../../assets/icons";
import Layout from "../layout/layout";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "../../contexts";
import { Screens } from "../../enums";

const DetailsItem = ({
	title,
	value,
	btnTitle,
	btnAction,
	fullWidth,
}: {
	title: string;
	fullWidth?: boolean;
	value: string | number;
	btnTitle?: string;
	btnAction?: () => void;
}) => {
	return (
		<div
			className={`flex w-full items-center justify-start gap-3 md:gap-8 py-6 border-b border-grey100 ${fullWidth && "justify-between !py-4 !border-grey200"}`}
		>
			<p
				className={`text-[10px] md:text-sm text-grey400 min-w-[85px] max-w-[85px] md:min-w-[114px] md:max-w-[114px] ${fullWidth && "!min-w-fit !text-sm"}`}
			>
				{title}
			</p>
			<h6
				className={`font-medium text-black text-[10px] md:text-sm break-words min-w-[90px] ${fullWidth && "!min-w-fit !text-sm"}`}
			>
				{value}
			</h6>
			{btnTitle && !fullWidth && (
				<div className="ml-auto">
					<Button
						title={btnTitle}
						onClick={btnAction}
						className="!min-w-fit !h-[30px] !px-2.5 md:!px-4 !bg-lightBlue !text-blue !text-[9px] md:!text-xs"
					/>
				</div>
			)}
		</div>
	);
};

export const IndividualProfile = () => {
	const navigate = useNavigate();
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
	const { profile } = useSelector((state: RootState) => state.customer);
	const { setCurrentScreen } = useNavigation();

	return (
		<>
			<Layout>
				<ListCardWrapper
					activeTabIndex={activeTabIndex}
					setActiveTabIndex={setActiveTabIndex}
					titleArray={[
						{ title: "Personal Information", icon: BusinessDetails },
						{ title: "Bank", icon: CardIcon },
						{ title: "Security", icon: SecurityIcon },
						// { title: "Notification" },
					]}
					actionComponent={undefined}
				>
					<div className="pt-8">
						{activeTabIndex === 0 && (
							<>
								<SectionTitle title={"Personal Information"} />
								<DetailsItem
									title={"Full Name:"}
									value={`${profile?.firstname} ${profile?.lastname}`}
								/>
								<DetailsItem
									title={"Account ID:"}
									value={profile?.customer_identifier || "N/A"}
								/>
								<DetailsItem
									title={"BVN:"}
									value={profile?.bvn || "N/A"}
									btnTitle={profile?.address ? "Edit" : "Add"}
								/>
								<DetailsItem
									title={"Address:"}
									value={profile?.address || "N/A"}
									btnTitle={profile?.address ? "Edit" : "Add"}
								/>
								<DetailsItem
									title={"Phone Number:"}
									value={profile?.phone_number || "N/A"}
									btnTitle={profile?.phone_number ? "Edit" : "Add"}
								/>
								<DetailsItem
									title={"Email Address:"}
									value={profile?.email_address || "N/A"}
									btnTitle={profile?.email_address ? "Edit" : "Add"}
								/>
								<DetailsItem
									title={"Next of KIN:"}
									value={profile?.next_of_kin_name || "N/A"}
									btnTitle={profile?.next_of_kin_name ? "Edit" : "Add"}
								/>
							</>
						)}

						{activeTabIndex === 1 && (
							<>
								<div className="flex items-center justify-between">
									<SectionTitle title={"Bank Details"} />
									<p
										className="text-xs font-semibold text-blue"
										onClick={() => navigate("/add-account")}
									>
										Add New Bank
									</p>
								</div>
								<div className="mt-6">
									{profile.bank.length < 1 ? (
										<div className="h-[300px] flex items-center justify-center">
											<EmptySection text={"Nothing here"} />
										</div>
									) : (
										profile.bank.map((bank: any, index: number) => (
											<div
												key={index}
												className="flex flex-col bg-grey100 rounded-xl px-4"
											>
												<DetailsItem
													title={"Bank Name:"}
													value={bank?.bankName}
													fullWidth
												/>
												<DetailsItem
													title={"Account Number:"}
													value={bank?.accountNumber}
													fullWidth
												/>
												<div
													onClick={() =>
														setCurrentScreen(Screens.deleteBankAccount)
													}
													className="text-xs text-red font-semibold my-4 self-end flex items-center gap-2"
												>
													<img
														src={deleteIcon}
														alt="del icon"
														className="w-[10px]"
													/>
													Remove Bank
												</div>
											</div>
										))
									)}
								</div>
							</>
						)}

						{activeTabIndex === 2 && (
							<>
								<SectionTitle title={"Security"} />
								<DetailsItem
									title={"Account Level:"}
									value={`Tier One`}
									btnTitle={"Upgrade"}
								/>
								<DetailsItem
									title={"Transaction PIN:"}
									value={`****`}
									btnTitle={"Change PIN"}
									btnAction={() => navigate("/reset-pin")}
								/>
								<DetailsItem
									title={"Two-Factor Authenticator:"}
									value={``}
									btnTitle="Activate"
								/>
								<DetailsItem
									title={"Password:"}
									value={`************`}
									btnTitle="Change Password"
									btnAction={() => setCurrentScreen(Screens.changePassword)}
								/>
							</>
						)}
					</div>
				</ListCardWrapper>
			</Layout>
		</>
	);
};
