/* eslint-disable react/react-in-jsx-scope */
import Layout from "../../components/layout/layout";
import { useSelector } from "react-redux";
import { account_type } from "../../utils";
import { RootState } from "../../redux/store";
import { MerchantEcardPage } from "../../components/merchant";
import { IndividualEcardPage } from "../../components/individual/individual-ecard-page";
// import { IndividualEcardPage } from "../../components/individual/individual-ecard-page";

const ECard = () => {
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType?.toLowerCase() || "individual");

	return <Layout>{isMerchant ? <MerchantEcardPage /> : <IndividualEcardPage />} </Layout>;
};

export default ECard;
