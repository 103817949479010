import React from "react";
import { useNavigation } from "../../contexts";
import { Screens } from "../../enums";
import DashiAccountDetails from "../../pages/dashi-account-details/dashi-account-details";
import PaymentOption from "../../pages/payment-option/payment-option";
import { Button, CreateEcardForm, MarkSavingsForm, SettlementForm, TopNavigation } from "../shared";
import { useLocation } from "react-router-dom";
import CustomModal from "../custom-modal/custom-modal";
import Success from "../../pages/success/success";
import { IndividualEcardDetails } from "../individual/individual-ecard-details";
import { CloseEcard } from "../../assets/icons";
import ChangePassword from "../../pages/change-password/change-password";
import ConfirmTransaction from "../../pages/confirm-transaction/confirm-transaction";
import EmailConfirmation from "../../pages/email-confirmation/email-confirmation";
import Error from "../../pages/error/error";
import FundingTransactionStatus from "../../pages/funding-transaction-status/funding-transaction-status";
import ResetPin from "../../pages/reset-passcode/reset-passcode";

export default function Modals() {
	const { pathname } = useLocation();
	const { currentScreen, setCurrentScreen } = useNavigation();

	switch (currentScreen) {
		case Screens.markSavings:
			return (
				<CustomModal>
					<div className="relative pt-14 md:pt-20">
						<TopNavigation
							onClick={() => setCurrentScreen(null)}
							rightTitle="MARK SAVINGS"
						/>
						<MarkSavingsForm />
					</div>
				</CustomModal>
			);
		case Screens.createEcard:
			return (
				<CustomModal>
					<div
						className={`relative ${pathname.includes("e-card/logs") ? "" : "pt-14 md:pt-20 "}`}
					>
						<CreateEcardForm />;
					</div>
				</CustomModal>
			);
		case Screens.settlement:
			return (
				<CustomModal>
					<div className="relative pt-10 md:pt-8">
						<TopNavigation
							onClick={() => setCurrentScreen(null)}
							rightTitle="WITHDRAW"
						/>
						<SettlementForm />{" "}
					</div>
				</CustomModal>
			);
		case Screens.paymentOption:
			return <PaymentOption />;
		case Screens.walletFunding:
			return <DashiAccountDetails />;
		case Screens.success:
			return <Success />;
		case Screens.error:
			return <Error />;
		case Screens.eCardDetails:
			return <IndividualEcardDetails />;
		case Screens.changePassword:
			return <ChangePassword />;
		case Screens.resetPin:
			return <ResetPin />;
		case Screens.fundingTransactionStatus:
			return <FundingTransactionStatus />;
		case Screens.confirmTransaction:
			return <ConfirmTransaction />;
		case Screens.emailConfirmation:
			return <EmailConfirmation />;
		case Screens.deleteBankAccount:
			return (
				<CustomModal>
					<div className="flex flex-col items-center justify-center gap-4">
						<img src={CloseEcard} alt="check" className="w-[60px]" />
						<div className="flex flex-col items-center justify-center gap-0">
							<h4 className="text-[18px] font-semibold text-black">Delete Account</h4>
							<p className="text-sm text-center text-grey400">
								Are you sure tou want to delete this account details
							</p>
						</div>
						<div className="flex items-center gap-3 justify-end">
							<Button
								isValid={true}
								type={"button"}
								title={"Cancel"}
								className="!w-[131px] !h-10 !text-xs"
								onClick={() => setCurrentScreen(null)}
							/>
							<Button
								isValid={true}
								type={"button"}
								title={"Yes Delete"}
								onClick={() => null}
								className="!w-[131px] !h-10 !bg-transparent border border-red !text-red !text-xs"
							/>
						</div>
					</div>
				</CustomModal>
			);
		default:
			return <></>;
	}
}
