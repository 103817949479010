/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { EmptySection } from "./empty-section";
import Transaction from "../transaction/transaction";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { account_type, groupTransactionsByDate } from "../../utils";
import { IGroupedTransactions, ITransactions } from "../../types";

export const DashboardTransactions = () => {
	const [data, setData] = useState<any>([]);
	const { transactions } = useSelector((state: RootState) => state.customer);

	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType?.toLowerCase() || "individual");

	const restructuredData: Array<IGroupedTransactions> = groupTransactionsByDate(data);

	useEffect(() => {
		if (transactions?.all?.length > 0) {
			const sortedData = [...transactions.all].sort(
				(a: any, b: any) =>
					new Date(b.created_date).getTime() - new Date(a.created_date).getTime(),
			);
			setData(sortedData.slice(0, 4));
		}
	}, [transactions]);

	const txData = isMerchant ? transactions?.all?.slice(0, 4) : restructuredData;

	return (
		<>
			{isMerchant ? (
				<div className="pt-[30px] flex flex-col gap-[30px] overflow-y-auto no-scrollbar">
					{txData?.length < 1 ? (
						<EmptySection
							text="Recent transactions will show up here"
							className="my-10 md:my-[90px]"
						/>
					) : (
						txData?.map((item: ITransactions, index: number) => (
							<div key={index} className="flex flex-col gap-4">
								<Transaction item={item} />
							</div>
						))
					)}
				</div>
			) : (
				<div className="pt-[30px] flex flex-col gap-[30px] overflow-y-auto no-scrollbar">
					{txData?.length < 1 ? (
						<EmptySection
							text="Recent transactions will show up here"
							className="my-10 md:my-[90px]"
						/>
					) : (
						txData?.reverse().map((group: IGroupedTransactions, index: number) => (
							<div key={index} className="flex flex-col gap-[30px]">
								<p className="text-grey400 text-xs font-medium">{group.date}</p>
								{group.transactions.map((item: ITransactions, index: number) => (
									<div key={index} className="flex flex-col gap-[25px]">
										<Transaction item={item} />
									</div>
								))}
							</div>
						))
					)}
				</div>
			)}
		</>
	);
};
