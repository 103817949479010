import React, { FC } from "react";
import DashboardItem from "../dashboard-item/dashboard-item";
import { GreenStar } from "../../../../../../assets/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { Link } from "react-router-dom";
import { formatAmount } from "../../../../../../utils";

export interface AccountTypeProps {
	isMerchant: boolean;
}

const Balance: FC<AccountTypeProps> = ({ isMerchant }) => {
	const { profile } = useSelector((state: RootState) => state.customer);

	const balance = profile?.total_amount_collected
		? formatAmount(profile?.total_amount_collected)
		: "0.00";

	return (
		<>
			{isMerchant ? (
				<div className="mt-6 flex items-center justify-between">
					<DashboardItem title={"Total Contributions"} value={`₦${balance}`} />
					<DashboardItem
						hasBtn
						title={"No. of Contributors"}
						value={profile?.no_of_contributors || "0"}
					/>
				</div>
			) : (
				<div className="mt-6 flex items-end justify-between">
					<DashboardItem title={"Savings Balance"} value={`₦${balance}`} />{" "}
					<Link
						to="/e-card/logs"
						className=" h-7 right-[55px] py-2 
          flex items-center justify-center gap-[6px] px-3 rounded-2xl
           text-white text-sm font-medium bg-[#ffffff1A] cursor-pointer"
					>
						<img src={GreenStar} alt="star" />
						Tier 1
					</Link>
				</div>
			)}
		</>
	);
};

export default Balance;
