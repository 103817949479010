/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SignIn from "./pages/sign-in/sign-in";
import ForgotPassword from "./pages/forgot-password/forgot-password";
import ResetPassword from "./pages/reset-password/reset-password";
import SignUp from "./pages/sign-up/sign-up";
import BusinessDetails from "./pages/business-details/business-details";
import Dashboard from "./pages/dashboard/dashboard";
import Transactions from "./pages/transactions/transactions";
import TransactionDetails from "./pages/transaction-details/transaction-details";
import MarkSavings from "./pages/mark-savings/mark-savings";
import ConfirmTransaction from "./pages/confirm-transaction/confirm-transaction";
import ContributorsInformation from "./pages/create-ecard/contributors-information/contributors-information";
import ECardVerification from "./pages/create-ecard/e-card-verification/e-card-verification";
import ECardDetails from "./pages/create-ecard/e-card-details/e-card-details";
// import Settlement from './pages/settlement/settlement';
import AccountSettings from "./pages/account-settings/account-settings";
import ECard from "./pages/e-card/E-card";
import MerchantECardDetails from "./pages/e-card-details/e-card-details";
import EditMerchant from "./pages/EditMerchant/EditMerchant";
import ChangePassword from "./pages/change-password/change-password";
import AddAccount from "./pages/add-account/add-account";
import BusinessIdentityVerification from "./pages/business-identity-verification/business-identity-verification";
import BusinessVerification from "./pages/business-verification/business-verification";
import { _CURRENT_USER, _DECODED_USER, _SIGN_OUT } from "./redux/auth/authActions";
import { store } from "./redux/store";
import axios from "axios";
import PrivateRoutes from "./components/private-routes/private-routes";
import Help from "./pages/help/help";
import Referrals from "./pages/referrals/referrals";
import CreatePin from "./pages/create-passcode/create-passcode";
import Settlement from "./pages/settlement/settlement";
import { jwtDecode } from "jwt-decode";
import HomeAddress from "./pages/home-address/home-address";
import BusinessProfile from "./pages/business-profile/business-profile";
import Providers from "./redux/provider";
import AccountLimits from "./pages/account-limits/account-limits";
import FundingTransactionStatus from "./pages/funding-transaction-status/funding-transaction-status";
import Vaults from "./pages/vaults/vaults";
import CreatePassword from "./pages/create-password/create-password";
import { NavigationProvider } from "./contexts";

const token: string | any = localStorage.getItem("token");

if (token) {
	store.dispatch(_CURRENT_USER({ token }));
} else {
	store.dispatch(_SIGN_OUT());
}

axios.interceptors.response.use(
	(response: any) => {
		return response;
	},
	(error: any) => {
		if (error.response.status === 401) {
			store.dispatch(_SIGN_OUT());
			window.location.reload();
		}

		return Promise.reject(error);
	},
);

function App() {
	useEffect(() => {
		const timer = window.setInterval(
			() => {
				store.dispatch(_SIGN_OUT());
				console.log("logging out after 15 mins", Date.now());
			},
			1000 * 60 * 15,
		);
		return () => {
			window.clearInterval(timer);
		};
	}, []);

	const jwt = localStorage.getItem("token");

	useEffect(() => {
		try {
			const decoded = jwtDecode(jwt || "");
			store.dispatch(_DECODED_USER({ ...decoded }));
		} catch (e) {
			console.log("Invalid token");
		}
	}, [jwt]);

	return (
		<div className="App">
			<Providers>
				<NavigationProvider>
					<BrowserRouter>
						<Routes>
							{/* <Route path="/account" element={<Welcome />} /> */}
							<Route path="/" element={<SignIn />} />
							<Route path="/sign-up" element={<SignUp />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/create-password" element={<ResetPassword />} />
							{/* <Route path="/email-confirmation" element={<EmailConfirmation />} /> */}
							{/* <Route path="/success" element={<Success />} /> */}
							{/* <Route path="/error" element={<Error />} /> */}
							<Route path="/create-pin" element={<CreatePin />} />
							<Route element={<PrivateRoutes />}>
								{/* <Route path="/reset-pin" element={<ResetPin />} /> */}
								<Route path="/verification" element={<BusinessVerification />} />
								<Route
									path="/home-address-verification"
									element={<HomeAddress />}
								/>

								<Route path="/business-details" element={<BusinessDetails />} />
								<Route
									path="/business-identity-verification"
									element={<BusinessIdentityVerification />}
								/>
								<Route path="/mark-savings" element={<MarkSavings />} />
								<Route path="/settlement" element={<Settlement />} />
								<Route
									path="/create-e-card/contributor-information"
									element={<ContributorsInformation />}
								/>
								<Route
									path="/create-e-card/verification"
									element={<ECardVerification />}
								/>
								<Route path="/create-e-card/details" element={<ECardDetails />} />
								<Route path="/transactions" element={<Transactions />} />
								<Route
									path="/transaction-details"
									element={<TransactionDetails />}
								/>
								<Route
									path="/confirm-transaction"
									element={<ConfirmTransaction />}
								/>
								{/* <Route path="/payment-option" element={<PaymentOption />} /> */}
								{/* <Route path="/wallet-funding" element={<DashiAccountDetails />} /> */}
								<Route
									path="/funding-transaction-status"
									element={<FundingTransactionStatus />}
								/>
								<Route path="/account-limits" element={<AccountLimits />} />
								<Route path="/account-settings" element={<AccountSettings />} />
								<Route path="/e-card/logs" element={<ECard />} />
								<Route path="/e-card/details" element={<MerchantECardDetails />} />
								<Route path="/edit-e-card" element={<EditMerchant />} />
								<Route path="/change-password" element={<ChangePassword />} />
								<Route path="/profile" element={<BusinessProfile />} />
								<Route path="/help" element={<Help />} />
								<Route path="/referrals" element={<Referrals />} />
								<Route path="/add-account" element={<AddAccount />} />
								<Route path="/dashboard" element={<Dashboard />} />
								<Route path="/vaults" element={<Vaults />} />
								<Route path="/set-password" element={<CreatePassword />} />
							</Route>
							<Route path="*" element={<Navigate to="/" />} />
						</Routes>{" "}
					</BrowserRouter>
				</NavigationProvider>
			</Providers>
		</div>
	);
}

export default App;
