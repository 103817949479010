import React, { FC } from "react";
import { FaCheck } from "react-icons/fa";

interface Props {
	htmlFor: string;
	isValid: boolean;
	validMessage: string;
	invalidMessage: string;
}

const Requirement: FC<Props> = ({ htmlFor, isValid, validMessage, invalidMessage }) => {
	return (
		<label htmlFor={htmlFor} className={`w-[50%] mt-2.5 ${!isValid ? "invalid" : "valid"} `}>
			<div className="flex items-center gap-2">
				<span className={` ${isValid ? "text-blue" : "text-grey400"}`}>
					<FaCheck size={10} />
				</span>
				<span className={`text-xs ${isValid ? "text-blue" : "text-grey400"}`}>
					{!isValid ? invalidMessage : validMessage}
				</span>
			</div>
		</label>
	);
};

export default Requirement;
