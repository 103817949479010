/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { account_type, ModeOfSettlement } from "../../utils";
import CustomModal from "../custom-modal/custom-modal";
import { Button } from "./button";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { InputField } from "./input-field";
import { SuccessCheck } from "../../assets/icons";
import { SelectField } from "./select-field";
import { useNavigation } from "../../contexts";
import { Screens } from "../../enums";

export const SettlementForm = () => {
	const [accountName] = useState<string | null>("");
	const [contributor, setContributor] = useState<any>();
	const [selectedEcard, setSelectedEcard] = useState<any>();
	const [isToAddNewBank, setIsToAddNewBank] = useState<boolean>(false);
	const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
	const [, setIsConfirmOpen] = useState<boolean>(false);
	const { profile } = useSelector((state: RootState) => state.customer);
	const { setCurrentScreen, setConfirmTransactionState } = useNavigation();

	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType?.toLowerCase() || "individual");

	const { contributors, eCards } = useSelector((state: RootState) => state.e_card);

	// const calculateRemainingBalance = (
	// 	percentage: number,
	// 	settlementAmount: number,
	// 	accountBalance: number,
	// ) => {
	// 	const additionalAmount = (percentage / 100) * accountBalance;

	// 	const totalAmount = settlementAmount + additionalAmount;

	// 	const remainingBalance = accountBalance - totalAmount;

	// 	return remainingBalance;
	// };

	const addDaysToDate = (dateTime: Date, daysToAdd: number) => {
		if (isNaN(daysToAdd) || daysToAdd < 0) {
			throw new Error("Invalid number of days. It should be a non-negative integer.");
		}

		const date = new Date(dateTime);

		date.setDate(date.getDate() + daysToAdd);

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth is zero-based
		const day = String(date.getDate()).padStart(2, "0");

		return `${day}/${month}/${year}`;
	};

	const eCardDate = addDaysToDate(
		selectedEcard?.created_date,
		selectedEcard?.product?.minimum_duration || 30,
	);

	return (
		<div
			className={` ${isMerchant ? "pt-[60px] md:pt-10" : "pt-5 md:pt-10"} flex flex-col gap-10`}
		>
			<Formik
				validateOnMount
				initialValues={{
					ecard_id: "",
					contributor_name: !isMerchant
						? `${profile.firstname} ${profile?.lastname}`
						: "",
					settlement_amount: "",
					mode_of_settlement: "",
				}}
				validationSchema={Yup.object({
					ecard_id: Yup.string().required("This is a required field"),
					contributor_name: Yup.string().required("This is a required field"),
					settlement_amount: Yup.number()
						.required("This is a required field")
						.max(selectedEcard?.total_saving_amount, "Insufficient balance"),
					mode_of_settlement: Yup.string().required("This is a required field"),
				})}
				onSubmit={async (values, { setErrors }) => {
					const errors = {};
					// const remainingBalance = calculateRemainingBalance(
					// 	3,
					// 	Number(values.settlement_amount),
					// 	selectedEcard.total_saving_amount,
					// );
					if (Object.entries(errors).length === 0 && errors.constructor === Object) {
						// if (selectedEcard.product.product_type === 'flexible') {

						setCurrentScreen(Screens.confirmTransaction);
						setConfirmTransactionState({ type: "settlement", ...values });

						// } else if (
						//   selectedEcard?.product?.product_type === 'fixed' &&
						//   maturityDate <= todaysDate
						// ) {
						//   navigate('/confirm-transaction', {
						//     state: { type: 'settlement', ...values },
						//   });
						// } else {
						//   setIsWarningOpen(true);
						//   setRemainingBalance(remainingBalance);
						// }
					} else {
						setErrors(errors);
					}
				}}
			>
				{({ isValid, handleSubmit, setFieldValue }) => (
					<Form onSubmit={handleSubmit} className="flex flex-col gap-[30px]">
						{isMerchant && (
							<SelectField
								isContributor
								data={contributors}
								label={"Select Contributor*"}
								placeholder={"eg Doe"}
								setValue={(item: any) => {
									setFieldValue("contributor_id", item.contributor_id);
									setContributor(item);
									if (item?.ecards?.length === 1) {
										setFieldValue("ecard_id", item.ecards[0]?.ecard_id);
										setSelectedEcard(item.ecards[0]);
									}
								}}
								setFieldValue={(value: string) =>
									setFieldValue("contributor_name", value)
								}
							/>
						)}

						{(contributor?.ecards?.length > 0 || !isMerchant) && (
							<SelectField
								data={isMerchant ? contributor?.ecards : eCards}
								label={"Select eCard*"}
								placeholder={"eg Savings"}
								setValue={(item: any) => {
									setFieldValue("ecard_id", item?.ecard_id);
									setSelectedEcard(item);
								}}
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								setFieldValue={(value: string) => null}
							/>
						)}

						<InputField
							type="number"
							inputMode="decimal"
							name="settlement_amount"
							label="Enter amount*"
							placeholder="0.00"
							bottomValue={selectedEcard?.total_saving_amount}
						/>

						{isMerchant && (
							<SelectField
								data={ModeOfSettlement}
								label={"Mode of settlement*"}
								placeholder={"Select mode"}
								setFieldValue={(value: string) =>
									setFieldValue("mode_of_settlement", value)
								}
							/>
						)}
						{!isMerchant && (
							<>
								{isToAddNewBank ? (
									<>
										<SelectField
											data={profile?.bank}
											label={"Select Bank*"}
											placeholder={"Select bank"}
											setFieldValue={(value: string) =>
												setFieldValue("mode_of_settlement", value)
											}
										/>

										<InputField
											type="number"
											name="accountNumber"
											isAccountNumber={true}
											label="Account Number*"
											placeholder="12345678"
											accountName={accountName}
										/>
									</>
								) : (
									<>
										<SelectField
											data={profile?.bank}
											label={"Select Account*"}
											placeholder={"Select account"}
											setFieldValue={(value: string) =>
												setFieldValue("mode_of_settlement", value)
											}
										/>

										<div
											onClick={() => setIsToAddNewBank(!isToAddNewBank)}
											className="text-blue underline underline-offset-2 text-sm"
										>
											New Bank Account
										</div>
									</>
								)}
							</>
						)}
						<div className="flex flex-col gap-[25px]">
							<Button
								isValid={isMerchant && isValid}
								type={"submit"}
								title={"Confirm Settlement"}
							/>
						</div>

						{isWarningOpen && (
							<CustomModal
								containerClassName="fixed top-0 right-0 left-0 z-[99999999]"
								className="md:!w-[410px] md:px-[53px] py-10"
							>
								<div className="flex flex-col items-center justify-center gap-[20px]">
									<img
										src={SuccessCheck}
										alt="check"
										className="w-[70px] md:w-[96px]"
									/>
									<div className="text-center">
										<h2 className="text-center text-slate-900 text-lg font-semibold">
											Early Withdrawal Alert
										</h2>
										<p className="mt-2.5 text-center text-neutral-400 text-xs font-normal leading-5 h-fit">
											Your savings will mature on {eCardDate}. Withdrawing now
											will incur a fee of 3% of your savings amount. We
											recommend waiting until the maturity date to avoid
											charges. Proceed with withdrawal?
										</p>
									</div>
									<div className="flex w-full items-center justify-center gap-2.5">
										<Button
											isValid={true}
											type={"button"}
											title="Cancel"
											onClick={() => {
												setIsWarningOpen(false);
											}}
											className="!w-[89px] !bg-lightRed !text-red"
										/>
										<Button
											isValid={true}
											type={"button"}
											title="Yes, proceed"
											onClick={() => {
												setIsWarningOpen(false);
												setIsConfirmOpen(true);
											}}
											className="!w-[172px]"
										/>
									</div>
								</div>
							</CustomModal>
						)}

						{/* {isConfirmOpen && (
							<CustomModal
								containerClassName="fixed top-0 right-0 left-0 z-[99999999]"
								className="md:!w-[410px] md:px-[53px] py-10"
							>
								<div className="flex flex-col items-center justify-center gap-[20px]">
									<img
										src={SuccessCheck}
										alt="check"
										className="w-[70px] md:w-[96px]"
									/>
									<div className="text-center">
										<h2 className="text-center text-slate-900 text-lg font-semibold">
											Early Withdrawal Alert
										</h2>
										<p className="mt-2.5 text-center text-neutral-400 text-xs font-normal leading-5 h-fit">
											You are about to withdraw [₦
											{formatAmount(values.settlement_amount)}] from your
											savings. After this transaction, your remaining balance
											will be [₦
											{formatAmount(remainingBalance)}]. Are you sure you want
											to proceed?date to avoid charges. Proceed with
											withdrawal?
										</p>
									</div>
									<div className="flex w-full items-center justify-center gap-2.5">
										<Button
											isValid={true}
											type={"button"}
											title="Cancel"
											onClick={() => {
												setIsConfirmOpen(false);
											}}
											className="!w-[89px] !bg-lightRed !text-red"
										/>
										<Button
											isValid={true}
											type={"button"}
											title="Yes, proceed"
											onClick={() => {
												navigate("/confirm-transaction", {
													state: { type: "settlement", ...values },
												});
											}}
											className="!w-[172px]"
										/>
									</div>
								</div>
							</CustomModal>
						)} */}
					</Form>
				)}
			</Formik>
		</div>
	);
};
