/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import CustomModal from "../../components/custom-modal/custom-modal";
import { useNavigate } from "react-router-dom";
import CodeConfirmation from "../../components/code-confirmation/code-confirmation";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { toastify } from "../../components/toastify/toastify";
import { truncate } from "../../utils";
import { _OTP_VERIFICATION } from "../../redux/customer/customerActions";
import { useNavigation } from "../../contexts";

const EmailConfirmation = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { confirmTransactionState, setCurrentScreen } = useNavigation();

	const email = localStorage.getItem("email") || "";
	const type: "settlement" | "savings" | "verification" = confirmTransactionState?.type;

	const handleSubmit = async (otp: string) => {
		if (type === "verification") {
			try {
				const payLoad = { email_address: email, otp, setCurrentScreen };
				const res: any = await dispatch(_OTP_VERIFICATION(payLoad)).unwrap();
				navigate("/create-pin");
				toastify("success", res.message);
			} catch (error: any) {
				toastify("error", error.message);
			}
		}
	};

	return (
		<CustomModal>
			<CodeConfirmation
				fields={6}
				type={type}
				title="Email Verification"
				subText={`Kindly input the 6-digits confirmation code that was sent to your
        		email ${truncate(email)}`}
				handleSubmit={handleSubmit}
			/>
		</CustomModal>
	);
};

export default EmailConfirmation;
