/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import Layout from "../../components/layout/layout";
import OnboardTitle from "../../components/onboard-title/onboard-title";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { toastify } from "../../components/toastify/toastify";
import { Button, InputField } from "../../components";
import PasswordRequirement from "../../components/password-requirement/password-requirement";
import { _CHANGE_PASSWORD } from "../../redux/customer/customerActions";
import { useNavigation } from "../../contexts";

const CreatePassword = () => {
	const dispatch = useDispatch<AppDispatch>();
	const [long, longEnough] = useState(false);
	const [number, hasNumber] = useState(false);
	const [hasSpecialChar, setHasSpecialChar] = useState(false);
	const [hasUppercase, setHasUppercase] = useState(false);
	const { user } = useSelector((state: RootState) => state.auth);
	const { loading } = useSelector((state: RootState) => state.customer);
	const { setCurrentScreen, setNotificationState } = useNavigation();

	return (
		<Layout>
			<OnboardTitle
				hasNoLogo
				title={"Create Password"}
				subText={`Hello ${user?.email_address}, Kindly create your password below.`}
			/>

			<Formik
				validateOnMount
				initialValues={{
					current_password: "",
					new_password: "",
					confirm_new_password: "",
				}}
				validationSchema={Yup.object({
					current_password: Yup.string().required("This is a required field"),

					new_password: Yup.string()
						.matches(
							/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,.\\/;':"-]).{8,}$/,
							"Must At Least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
						)
						.required("This is a required field"),
					confirm_new_password: Yup.string()
						.required("This is a required field")
						.test("passwords-match", "Passwords must match", function (value) {
							return this.parent.new_password === value;
						}),
				})}
				validate={values => {
					const password = values.new_password;
					password.length < 8 ? longEnough(false) : longEnough(true);
					/[A-Z]/.test(password) ? setHasUppercase(true) : setHasUppercase(false);
					/[0-9]/.test(password) ? hasNumber(true) : hasNumber(false);
					/[!@#$%^&*()_+,.\\/;':"-]/.test(password)
						? setHasSpecialChar(true)
						: setHasSpecialChar(false);
				}}
				onSubmit={async (values, { setErrors }) => {
					const errors = {};
					if (Object.entries(errors).length === 0 && errors.constructor === Object) {
						try {
							await dispatch(
								_CHANGE_PASSWORD({
									...values,
									setNotificationState,
									setCurrentScreen,
								}),
							).unwrap();
						} catch (error: any) {
							toastify("error", error);
						}
					} else {
						setErrors(errors);
					}
				}}
			>
				{({ isValid, handleSubmit }) => (
					<Form onSubmit={handleSubmit} className="mt-10 flex flex-col gap-[30px]">
						<InputField
							type={"password"}
							label={"Default Password*"}
							name={"current_password"}
							placeholder={"Password"}
						/>

						<div>
							<InputField
								type={"password"}
								label={"New Password*"}
								name={"new_password"}
								placeholder={"Password (8+ characters)"}
							/>

							<PasswordRequirement
								long={long}
								number={number}
								hasUppercase={hasUppercase}
								hasSpecialChar={hasSpecialChar}
							/>
						</div>

						<InputField
							type={"password"}
							label={"Confirm New Password*"}
							name={"confirm_new_password"}
							placeholder={"Password (8+ characters)"}
						/>
						<Button
							isValid={isValid}
							isLoading={loading}
							type={"submit"}
							title={"Confirm"}
						/>
					</Form>
				)}
			</Formik>
		</Layout>
	);
};

export default CreatePassword;
