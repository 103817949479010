import React, { useEffect } from "react";
import Layout from "../../components/layout/layout";
import { account_type } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import AddToHomeScreen from "../../components/add-to-home-screen/AddToHomeScreen";
import { _GET_TRANSACTIONS, _PROFILE } from "../../redux/customer/customerActions";
import { _GET_CONTRIBUTORS, _GET_E_CARDS } from "../../redux/e-card/e_cardAction";
import MerchantDashoard from "./components/merchant-dashboard/merchant-dashboard";
import IndividualDashboard from "./components/individual-dashboard/individual-dashboard";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType?.toLowerCase() || "individual");

	useEffect(() => {
		dispatch(_GET_TRANSACTIONS());
		dispatch(_PROFILE(user?.userType?.toLowerCase() || "individual"));
		dispatch(_GET_E_CARDS(user?.userType?.toLowerCase() || "individual"));
		if (isMerchant) dispatch(_GET_CONTRIBUTORS());
	}, [user.userType]);

	useEffect(() => {
		if (user.isPasswordChanged) {
			navigate("/set-password");
		}
	}, [user]);

	return (
		<Layout>
			{isMerchant ? <MerchantDashoard /> : <IndividualDashboard />}
			<AddToHomeScreen />
		</Layout>
	);
};

export default Dashboard;
