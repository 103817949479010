import React, { FC } from "react";
import Requirement from "./requiremt";

interface Props {
	long: boolean;
	number: boolean;
	hasUppercase: boolean;
	hasSpecialChar: boolean;
}

const PasswordRequirement: FC<Props> = ({ long, number, hasUppercase, hasSpecialChar }) => {
	return (
		<div className="flex flex-wrap justify-between">
			<Requirement
				htmlFor="password"
				isValid={long}
				invalidMessage="At least 8 Characters"
				validMessage="At least 8 Characters"
			/>
			<Requirement
				htmlFor="password"
				isValid={hasSpecialChar}
				invalidMessage="At most one a special Character"
				validMessage="At most one a special Character"
			/>

			<Requirement
				htmlFor="password"
				isValid={hasUppercase}
				invalidMessage="At least one Capital Letter"
				validMessage="At least one Capital Letter"
			/>
			<Requirement
				htmlFor="password"
				isValid={number}
				invalidMessage="At least One Number"
				validMessage="At least One Number"
			/>
		</div>
	);
};

export default PasswordRequirement;
