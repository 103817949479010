/* eslint-disable react/prop-types */
import { ErrorMessage, useField } from "formik";
import React, { FC } from "react";

interface FieldProps {
	type?: string;
	label: string;
	name: string;
	value?: string;
	disabled?: boolean;
	placeholder: string;
	classname?: string;
}

const MerchantInputField: FC<FieldProps> = ({
	label,
	type,
	value,
	name,
	disabled,
	classname,
	placeholder,
}) => {
	const [field] = useField(name);
	return (
		<div>
			<div
				className={`bg-grey100 w-full pl-[1rem] rounded-[.5rem] flex flex-col h-[48px] gap-2.5 py-1 ${classname}`}
			>
				<label className="text-grey400 text-[10px] text-[Montserrat] h-5 -mt-1">
					{label}
				</label>
				<input
					type={type}
					className={`w-full bg-transparent outline-none font-semibold text-xs text-black -mt-[8px] h-[18px] capitalize ${value ? "placeholder:text-black" : ""}`}
					placeholder={placeholder}
					disabled={disabled}
					{...field}
				/>
			</div>

			<ErrorMessage
				name={field.name}
				component={"div"}
				className="mt-2 text-red text-[10px]"
			/>
		</div>
	);
};

export default MerchantInputField;

//   // type,
// //   label,
// //   name,
// //   className,
// //   placeholder,
// // }) => {
// //   const [field, meta] = useField(name);
// //   const [isVisible, setIsVisible] = useState<boolean>(false);
// //   const toggleVisibility = () => setIsVisible(!isVisible);

//   return (
// //     <div className={`relative flex flex-col gap-[10px] ${className}`}>
// //       <label htmlFor="" className="text-xs font-medium leading-[14px]">
// //         {label}
// //       </label>
// //       <input
// //         type={isVisible || !type ? 'text' : type}
// //         placeholder={placeholder}
// //         className={`h-[46px] p-[15px] bg-gray-50 rounded-[32px]
// //         text-xs font-normal leading-[14px] text-grey400 outline-green
// //         focus:border focus:!border-green focus:bg-lightGreen placeholder-grey300 ${
// //           meta.touched && meta.error && 'border border-red bg-lightRed'
// //         } ${type === 'tel' && 'pl-[65px]'}`}
// //         {...field}
// //       />

// //       {type === 'tel' && (
// //         <span
// //           className="text-grey400 text-xs font-semibold leading-[14px]
// //           border-r border-grey300 pr-[10px] absolute w-fit h-fit top-[40px] pl-[15px]"
// //         >
// //           +234
// //         </span>
// //       )}

// //       {type === 'date' && (
// //         <span className="absolute cursor-pointer z-40 top-[32px] translate-y-[36%]	right-[15px]">
// //           <img src={Calendar} alt="Calendar" />
// //         </span>
// //       )}

// //       {type === 'password' && (
// //         <span
// //           onClick={toggleVisibility}
// //           className="absolute cursor-pointer top-[28px] translate-y-[36%]	right-[15px]"
// //         >
// //           {isVisible ? (
// //             <img src={Eye} alt="eye" width={20} height={20} />
// //           ) : (
// //             <img src={Eye} alt="eye-slash" width={20} height={20} />
// //           )}
// //         </span>
// //       )}

// //       <ErrorMessage
// //         component="p"
// //         name={field.name}
// //         className="text-red text-xs font-medium leading-[14px]"
// //       />
// //     </div>
//   );
// };
