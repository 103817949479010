import React, { FC } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarItem from "./sidebar-item/sidebar-item";
import { SidebarArray } from "../../../../../../utils";
import { RedProfile } from "../../../../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../redux/store";
import { _SIGN_OUT } from "../../../../../../redux/auth/authActions";
import { ISidebarItem } from "../../../../../../types";
import { TopNavigation } from "../../../../../../components";

interface Props {
	isOpen: boolean;
	onClick: () => void;
}

const Sidebar: FC<Props> = ({ isOpen, onClick }) => {
	const { user } = useSelector((state: RootState) => state.auth);
	const accountType = user?.userType?.toLowerCase() || "individual";
	const array = SidebarArray(accountType);
	const dispatch = useDispatch<AppDispatch>();

	return (
		<div className={`overflow-hidden h-fit ${isOpen && "pt-[51px]"}`}>
			<AnimatePresence>
				{isOpen && (
					<motion.div
						initial={{
							height: "100vh",
							right: -1000,
						}}
						animate={{
							height: "100vh",
							right: 0,
							transition: {
								right: {
									duration: 0.4,
								},
							},
						}}
						exit={{
							right: -1000,
							transition: {
								height: {
									duration: 0.4,
								},
							},
						}}
						className="absolute top-0 left-0 right-0 w-full min-h-[735px] max-h-[765px] 
            			md:max-h-[735px] bg-white mx-auto md:rounded-lg overflow-hidden z-50 "
					>
						<TopNavigation onClick={onClick} />
						<div className="relative pt-[60px] md:pt-20 !h-fit flex flex-col gap-[50px] w-full">
							{array.map((item: ISidebarItem, index: number) => (
								<SidebarItem
									key={index}
									icon={item.icon}
									title={item.title}
									url={Array.isArray(item.url) ? item.url[0] : item.url}
								/>
							))}
						</div>
						<SidebarItem
							icon={RedProfile}
							title={"Logout"}
							onclick={() => {
								dispatch(_SIGN_OUT());
							}}
							textStyles="text-red"
							iconStyles="!bg-[#FFF6F6]"
							className="absolute bottom-[70px] md:bottom-9 left-0 right-0 z-40"
						/>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default Sidebar;
