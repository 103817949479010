/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
	createContext,
	useContext,
	useState,
	ReactNode,
	Dispatch,
	SetStateAction,
} from "react";
import { Screens } from "../enums";

// Define the interface for the additional state
interface NavigationState {
	title: string;
	redirectTo: string;
	message: string;
	icon?: any;
	lowerText?: string;
	btnTitle?: string;
	lowerTextUrl?: string;
}

interface NavigationContextProps {
	email: string | null;
	eCardDetails: any;
	depositDetails: any;
	currentScreen: string | null;
	confirmTransactionState: any;
	setCurrentScreen: Dispatch<SetStateAction<Screens | null>>;
	setEmail: Dispatch<SetStateAction<string | null>>;
	setEcardDetails: Dispatch<SetStateAction<any | null>>;
	setDepositDetails: Dispatch<SetStateAction<any | null>>;
	setConfirmTransactionState: Dispatch<SetStateAction<any | null>>;
	notificationState: NavigationState | null;
	setNotificationState: Dispatch<SetStateAction<NavigationState | null>>;
}

const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

export const NavigationProvider = ({ children }: { children: ReactNode }) => {
	const [currentScreen, setCurrentScreen] = useState<Screens | null>(null);
	const [notificationState, setNotificationState] = useState<NavigationState | null>(null);
	const [eCardDetails, setEcardDetails] = useState<any>(null);
	const [confirmTransactionState, setConfirmTransactionState] = useState<any>(null);
	const [email, setEmail] = useState<string | null>(null);
	const [depositDetails, setDepositDetails] = useState<any>(null);

	return (
		<NavigationContext.Provider
			value={{
				depositDetails,
				setDepositDetails,
				email,
				setEmail,
				confirmTransactionState,
				setConfirmTransactionState,
				eCardDetails,
				setEcardDetails,
				currentScreen,
				setCurrentScreen,
				notificationState,
				setNotificationState,
			}}
		>
			{children}
		</NavigationContext.Provider>
	);
};

export const useNavigation = () => {
	const context = useContext(NavigationContext);
	if (!context) {
		throw new Error("useNavigation must be used within a NavigationProvider");
	}
	return context;
};
