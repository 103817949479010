/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { _GET_CONTRIBUTORS, _GET_E_CARDS } from "../../../redux/e-card/e_cardAction";
import { useNavigate } from "react-router-dom";
import { account_type, formatAmount } from "../../../utils";
import { EmptySection, Initials } from "../../../components";
import { ECardAvatar } from "../../../assets/icons";
import { useNavigation } from "../../../contexts";
import { Screens } from "../../../enums";

/* eslint-disable react/react-in-jsx-scope */
const EcardLogs = ({ query }: { query: string }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { user } = useSelector((state: RootState) => state.auth);
	const { eCards } = useSelector((state: RootState) => state.e_card);
	const { profile } = useSelector((state: RootState) => state.customer);
	const isMerchant = account_type(user?.userType?.toLowerCase() || "individual");
	const { setCurrentScreen, setEcardDetails } = useNavigation();

	useEffect(() => {
		if (isMerchant) dispatch(_GET_CONTRIBUTORS());
		dispatch(_GET_E_CARDS(user?.userType?.toLowerCase() || "individual"));
	}, []);

	return (
		<>
			<div>
				{isMerchant && (
					<div className="flex justify-between text-[12px] text-[#8F8F8F] font-['Montserrat']">
						<div> Contributor</div>
						<div className="pr-2 w-[94px]"> Amount</div>
					</div>
				)}
				<div className=" text-[12px] font-['Montserrat'] md:h-[516px] overflow-x-auto no-scrollbar">
					{eCards?.length < 1 ? (
						<EmptySection
							text="Recent e-cards will show up here"
							className="my-20 md:my-[90px]"
						/>
					) : (
						eCards
							?.filter(
								(obj: any) =>
									query === "" ||
									obj?.contributor?.first_name
										?.toLowerCase()
										?.includes(query.toLowerCase()) ||
									obj?.contributor?.last_name
										?.toLowerCase()
										?.includes(query.toLowerCase()),
							)
							.map(
								(eachData: any, index: number): JSX.Element => (
									<div
										onClick={() => {
											setEcardDetails(eachData);

											if (isMerchant) {
												navigate("/e-card/details", {
													state: { data: eachData },
												});
											} else {
												setCurrentScreen(Screens.eCardDetails);
											}
										}}
										key={index}
										className={`flex items-center justify-between py-[1.8rem] cursor-pointer  ${
											eCards?.length - 1 === index
												? "!border-none !pb-0"
												: "border-b border-[#EFEFEF]"
										}`}
									>
										<div className="flex items-center gap-[10px]">
											{" "}
											<div className="">
												{isMerchant ? (
													<Initials
														fullName={`${eachData?.ecard_name || profile?.firstname} ${eachData?.ecard_name.slice(1) || profile?.lastname}`}
													/>
												) : (
													<img
														src={ECardAvatar}
														alt="avatar"
														className="w-5 h-5 md:w-[48px] md:h-[48px]"
													/>
												)}
											</div>
											<div>
												<h2
													className={`font-semibold ${isMerchant ? "text-sm" : "text-xs"}`}
												>
													{isMerchant
														? `${eachData?.contributor?.first_name || profile?.firstname} ${eachData?.contributor?.last_name || profile?.lastname}`
														: eachData?.ecard_name}
												</h2>
												{isMerchant && (
													<h2 className="!text-xs text-[#8F8F8F]">
														{eachData?.ecard_name}
													</h2>
												)}
											</div>
										</div>
										<div className="">
											<div className={`font-medium text-xs`}>
												{" "}
												&#x20A6;
												{formatAmount(
													eachData?.product?.minimum_amount,
												)}{" "}
												<span className="text-grey400 capitalize">
													/ {eachData.product?.minimum_frequency}
												</span>
											</div>
										</div>
									</div>
								),
							)
					)}
				</div>
			</div>
		</>
	);
};

export default EcardLogs;

// ( _eachData : { imageUrl: string; title : string; date : string; amount : string; }, _index : number) => {

// }
