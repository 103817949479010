/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
// import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import MerchantInputField from "./Merchant-E-card-input-field";
import SectionTitle from "../../create-ecard/section-title/section-title";
import {
	FemaleAvatar,
	MaleAvatar,
	PlusIcon,
	UserAvatar,
	editIcon,
	merchantStar,
} from "../../../assets/icons";
import { Form, Formik } from "formik";
import { account_type, daysToMonths, formatAmount, getDateString, truncate } from "../../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import { Button } from "../../../components";
import { useNavigation } from "../../../contexts";

/* eslint-disable react/react-in-jsx-scope */
const MerchantECardInput = () => {
	const navigate = useNavigate();
	const [details, setDetails] = useState<any>({});
	const { user } = useSelector((state: RootState) => state.auth);
	const { profile } = useSelector((state: RootState) => state.customer);
	const isMerchant = account_type(user?.userType?.toLowerCase() || "individual");
	const { eCardDetails } = useNavigation();

	const displayName = isMerchant
		? `${eCardDetails?.contributor?.first_name || ""} ${eCardDetails?.contributor?.last_name || ""}`
		: `${profile?.firstname || ""} ${profile?.lastname || ""}` || "";

	const isEcard = eCardDetails?.product && true;

	useEffect(() => {
		if (isEcard) {
			setDetails(eCardDetails);
		} else {
			setDetails(profile);
		}
	}, [eCardDetails, profile]);

	return (
		<>
			<Formik
				validateOnMount
				enableReinitialize
				initialValues={{
					profile_pic: details?.profile_pic || "",
					date_of_birth: details?.date_of_birth || "",
					email: truncate(details?.email ? details?.email : details?.email_address) || "",
					phone_number: isMerchant
						? details?.phone_number
							? details?.phone_number.length === 10
								? `+234${details?.phone_number}`
								: details?.phone_number
							: ""
						: profile.phone_number,
					gender: details?.gender ? (details?.gender === 1 ? "male" : "female") : "",
					bvn: details?.bvn || "",
					nin: details?.bvn || "",
					product_type: details?.product?.product_type || "",
					minimum_amount:
						`₦${formatAmount(details?.product?.minimum_amount)}/${details?.product?.minimum_frequency}` ||
						"",
					minimum_frequency: details?.product?.product?.minimum_frequency || "",
					minimum_duration: daysToMonths(details?.product?.minimum_duration || "") || "",
					residential_address: details?.residential_address || "",
					nok_first_name: details?.nok_first_name || "",
					nok_phone_number: details?.nok_phone_number || "",
					nok_email_address: details?.nok_email_address || "",
				}}
				validationSchema={Yup.object({
					email: Yup.string().required("This is a required field"),
					phone_number: Yup.number().required("This is a required field"),
					gender: Yup.string().required("This is a required field"),
					bvn: Yup.string().required("This is a required field"),
					nin: Yup.string().required("This is a required field"),
					product_type: Yup.string().required("This is a required field"),
					minimum_amount: Yup.string().required("This is a required field"),
					minimum_frequency: Yup.string().required("This is a required field"),
					minimum_duration: Yup.string().required("This is a required field"),
					residential_address: Yup.string().required("This is a required field"),
				})}
				onSubmit={(values, { setErrors }) => {
					const errors = {};
					if (Object.entries(errors).length === 0 && errors.constructor === Object) {
						navigate("/dashboard");
					} else {
						setErrors(errors);
					}
				}}
			>
				{({ values, handleSubmit }) => (
					<Form onSubmit={handleSubmit}>
						<div className="flex items-center justify-center mt-[60px] md:mt-10 ">
							<img
								src={
									values.profile_pic || details?.gender === 1
										? MaleAvatar
										: details?.gender === 2
											? FemaleAvatar
											: UserAvatar
								}
								alt="avatar"
								className="w-20 h-20 mb-2.5"
							/>
						</div>

						<div className="flex items-baseline gap-[5px] justify-center">
							<p className="text-[16px] mb-[.5rem] font-semibold">{displayName}</p>
							<img src={merchantStar} className="h-fit" alt="star" />
						</div>
						{isEcard && (
							<span className="flex  justify-center text-[14px] text-[#8F8F8F] space-x-4">
								{" "}
								{details?.contributor_identifier && (
									<>
										<h2 className="uppercase">
											CN: {details?.contributor_identifier}
										</h2>{" "}
										<h2>|</h2>{" "}
									</>
								)}
								<h2>
									{" "}
									&#x20A6;{formatAmount(details?.product?.minimum_amount)}/
									{details?.product?.minimum_frequency}
								</h2>
							</span>
						)}

						<div>
							<SectionTitle
								title={"Personal Information"}
								className={
									"text-grey400 text-xs border-b border-grey100 h-[30px] mt-10"
								}
							/>

							<div className="py-[1rem] gap-5 flex flex-wrap justify-between ">
								{!isEcard && (
									<div className="!w-[47.3%]">
										<MerchantInputField
											label="Email"
											name="email"
											placeholder=""
											type="email"
											classname=""
											disabled
										/>
									</div>
								)}
								<div className="!w-[47.3%]">
									<MerchantInputField
										label="Phone Number"
										name="phone_number"
										placeholder=""
										type="phone"
										classname=""
										disabled
									/>
								</div>

								<div className="!w-[47.3%]">
									<MerchantInputField
										label="Gender"
										name="gender"
										placeholder=""
										type="text"
										classname=""
										disabled
									/>
								</div>

								{!isEcard && (
									<div className="!w-[47.3%]">
										<MerchantInputField
											label="Date of Birth"
											name="date_of_birth"
											placeholder=""
											type="text"
											classname=""
											disabled
										/>
									</div>
								)}
							</div>

							<MerchantInputField
								label="Residential Address"
								name="residential_address"
								placeholder=""
								type="text"
								classname=""
								disabled
							/>
						</div>

						{isEcard && (
							<div className="py-[1rem] gap-5 flex justify-between ">
								<div className="!w-[50%]">
									<MerchantInputField
										label="BVN"
										name="bvn"
										placeholder=""
										type="text"
										disabled
									/>
								</div>

								<div className="!w-[50%]">
									<MerchantInputField
										label="NIN"
										name="nin"
										placeholder=""
										disabled
									/>
								</div>
							</div>
						)}

						{isEcard && (
							<div>
								<SectionTitle
									title={"eCard Details"}
									className={
										"text-grey400 text-xs border-b border-grey100 h-[30px] mt-10"
									}
								/>
								<div className="py-[1rem] flex gap-5 justify-between ">
									<div className="!w-[50%]">
										<MerchantInputField
											label="Product type"
											name="product_type"
											placeholder=""
											type="text"
											classname=""
											disabled
										/>
									</div>

									<div className="!w-[50%]">
										<MerchantInputField
											label="Amount/Frequency"
											name="minimum_amount"
											placeholder=""
											classname=""
											value={`₦${formatAmount(details?.product?.minimum_amount)}/${details?.product?.minimum_frequency}`}
											disabled
										/>
									</div>
								</div>

								{values.product_type.toLocaleLowerCase() === "fixed" && (
									<MerchantInputField
										label="Duration"
										name="minimum_duration"
										placeholder=""
										type="text"
										classname=""
										disabled
									/>
								)}
							</div>
						)}

						{!isEcard && (
							<div>
								<div className="flex items-center justify-between border-b border-grey100 h-[30px] mt-10">
									<SectionTitle
										title={"Bank Accounts"}
										className={"text-grey400 text-xs "}
									/>

									<div
										onClick={() => navigate("/add-account")}
										className="flex items-center gap-1 text-xs text-blue font-semibold"
									>
										<img src={PlusIcon} alt="plus" />
										Add New Bank
									</div>
								</div>

								<div className="py-[1rem] gap-5 flex flex-wrap justify-between ">
									{details?.bank?.map((_: void, index: number) => (
										<div key={index} className="!w-[47.3%]">
											<MerchantInputField
												label="Email"
												name="email"
												placeholder=""
												type="email"
												classname=""
												disabled
											/>
										</div>
									))}
								</div>
							</div>
						)}

						{!isEcard && (
							<div>
								<SectionTitle
									title={"Next of Kin"}
									className={
										"text-grey400 text-xs border-b border-grey100 h-[30px] mt-10"
									}
								/>
								<div className="py-[1rem] flex gap-5 justify-between ">
									<div className="!w-[50%]">
										<MerchantInputField
											label="First name"
											name="nok_first_name"
											placeholder=""
											type="text"
											classname=""
											disabled
										/>
									</div>

									<div className="!w-[50%]">
										<MerchantInputField
											label="Phone Number"
											name="nok_phone_number"
											placeholder=""
											type="phone"
											classname=""
											disabled
										/>
									</div>
								</div>
								<MerchantInputField
									label="Email Address"
									name="nok_email_address"
									placeholder=""
									type="email"
									classname=""
									disabled
								/>
							</div>
						)}
						<h4 className="text-[12px] w-fit py-[.2rem] px-[.5rem] mt-[3rem] bg-grey100 rounded-lg text-grey400">
							Account created at: {getDateString(details?.created_date)}
						</h4>

						{isMerchant && (
							<Button
								isValid={true}
								type={"button"}
								title={"Edit Details"}
								icon={editIcon}
								className="!bg-transparent border border-blue !text-blue mt-10"
								onClick={() =>
									navigate("/edit-e-card", { state: { data: eCardDetails } })
								}
							/>
						)}
					</Form>
				)}
			</Formik>
		</>
	);
};

export default MerchantECardInput;
