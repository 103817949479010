import React from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/layout/layout";
import { MarkSavings, Settlement, ShareIcon } from "../../assets/icons";
import TransactionStatus from "../success/transaction-staus/transaction-staus";
import DetailsItem from "../../components/details-item/details-item";
import { account_type, formatAmount, getDateString, getTimeString } from "../../utils";
import { Button, TopNavigation } from "../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const TransactionDetails = () => {
	const { state } = useLocation();
	const data = state?.transaction;
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType?.toLowerCase() || "individual");

	return (
		<Layout>
			{isMerchant && <TopNavigation middleTitle="Transaction Details" />}

			<div className="mt-[80px] md:mt-10 flex flex-col items-center justify-center">
				<div
					className={`w-[64px] h-[64px] flex items-center justify-center rounded-[10px] ${
						data.transaction_type === "savings" ? "green-gradient" : "purple-gradient"
					}`}
				>
					<div className="w-[44px] h-[44px] flex items-center justify-center bg-white rounded-[7px]">
						<img
							alt="icon"
							className="w-6 h-6"
							src={data?.transaction_type === "savings" ? MarkSavings : Settlement}
						/>
					</div>
				</div>

				<h4 className="mt-5 text-center text-[22px] md:text-[32px] font-bold">
					₦
					{data?.amount_collected
						? formatAmount(data?.amount_collected)
						: data?.amount_settled
							? formatAmount(data?.amount_settled)
							: formatAmount(data?.amount)}
				</h4>

				<TransactionStatus status={data?.status} className="mt-2.5" />
			</div>

			<div className="mt-10 flex flex-col gap-[15px]">
				{/* <DetailsItem
				canCopy
				value={data?.reference}
				title={'Transaction Reference'}
				/> */}
				<DetailsItem
					title={"Date"}
					value={`${getDateString(data?.created_date)} @ ${getTimeString(data?.created_date)}`}
				/>
				<DetailsItem title={"Description"} value={data?.description || "--"} />
				<div className="flex  items-center gap-5">
					<DetailsItem title={"Payment Method"} value={data?.payment_method} />
					<DetailsItem title={"Fees"} value={`₦0.00`} />
				</div>
			</div>

			<Button
				isValid={true}
				type={"button"}
				title={
					<span className="flex items-center w-full justify-center gap-2.5">
						<img src={ShareIcon} alt="share-icon" />
						Share receipt
					</span>
				}
				className="border border-zinc-300 bg-transparent !text-grey400 mt-10"
			/>
		</Layout>
	);
};

export default TransactionDetails;
