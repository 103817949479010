/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import CustomModal from "../../components/custom-modal/custom-modal";
import { Copy, Star } from "../../assets/icons";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import SectionTitle from "../create-ecard/section-title/section-title";
import { PaymentOptionEnum } from "../payment-option/payment-option";
import { Button } from "../../components";
import { useNavigation } from "../../contexts";
import { Screens } from "../../enums";

const DashiAccountDetails = () => {
	const accountNumber = "1008214099";
	const bankName = "LOTUS Bank";
	const accountName = "DASHI TECHNOLOGIES LIMITED";
	const { setCurrentScreen, setDepositDetails, depositDetails } = useNavigation();
	console.log("🚀 ~ DashiAccountDetails ~ depositDetails:", depositDetails);

	const instantWalletFunding = [
		<p key={"first in"}>
			A charge of{" "}
			<span className="text-black font-medium"> 0.75% of the amount (Capped at N200)</span>{" "}
			would belevied on the incoming amount by the payment processor.
		</p>,
		<p key={"asdf"}>
			Please wait 5 - 10mins for your transaction to reflect. If you have not been credited
			within that period, send to us message via WhatsApp{" "}
			<Link className="font-bold text-blue" to={"https://wa.me/+2348068849845"}>
				wa.me/+2348068849845
			</Link>
		</p>,
	];

	const bankTransfer = [
		<p key={"reference"}>
			Please include your reference number <span className="font-bold text-black">31709</span>{" "}
			need when making the transfer
		</p>,
		<div key={"asdf"}>
			Kindly upload the transfer confirmation here or send to us via WhatsApp{" "}
			<Link className="font-bold text-blue" to={"https://wa.me/+2348068849845"}>
				wa.me/+2348068849845
			</Link>
		</div>,
	];

	const instructionsMap: Record<PaymentOptionEnum, any[]> = {
		[PaymentOptionEnum.InstantWalletFunding]: instantWalletFunding,
		[PaymentOptionEnum.BankTransfer]: bankTransfer,
	};

	// Function to retrieve instructions based on type
	const getInstructions = (type: PaymentOptionEnum): JSX.Element[] => {
		return instructionsMap[type] || [];
	};
	const instructions = getInstructions(depositDetails?.fundingType);

	return (
		<CustomModal>
			<div className="flex flex-col items-center justify-center gap-6 w-full">
				<div className="flex flex-col gap-[15px] items-center">
					<SectionTitle
						title={depositDetails?.fundingType}
						className="text-lg md:text-2xl !font-bold"
					/>
					<h2 className="text-center text-grey400 text-sm md:text-base">
						Make a payment of{" "}
						<span className="text-black font-bold">NGN {depositDetails?.amount} </span>{" "}
						to any of the accounts below
					</h2>
				</div>

				{depositDetails?.fundingType === PaymentOptionEnum.InstantWalletFunding && (
					<div className="flex flex-col gap-3">
						{instructions.map((item: any, index: number) => (
							<div
								key={index}
								className="text-xs text-grey400 flex items-start gap-2.5 leading-5"
							>
								<div className="min-w-10 h-10 flex items-center justify-center -mt-2">
									<img src={Star} alt="star" className="min-w-6 min-h-6" />
								</div>
								<p>{item}</p>
							</div>
						))}
					</div>
				)}
				{depositDetails?.fundingType === PaymentOptionEnum.BankTransfer ? (
					<>
						<div className="text-center w-full">
							<div className="w-full h-[170px] bg-grey100 rounded-lg flex">
								<div className="flex flex-col gap-6 mx-auto items-center y-auto self-center">
									<p className="text-xs font-semibold text-black">{bankName}</p>
									<h4 className="font-bold text-black text-xl md:text-3xl flex items-center w-fit gap-1">
										{accountNumber}{" "}
										<CopyToClipboard
											text={accountNumber}
											onCopy={() => toast.success("Account number copied")}
										>
											<img
												src={Copy}
												alt="copy icon"
												className="cursor-pointer"
											/>
										</CopyToClipboard>
									</h4>
									<p className="text-xs font-semibold text-black">
										{accountName}
									</p>
								</div>
							</div>
						</div>
						<div className="text-xs text-grey400 flex items-start gap-2.5 leading-5">
							<div className="min-w-10 h-10 flex items-center justify-center -mt-2">
								<img src={Star} alt="star" className="min-w-6 min-h-6" />
							</div>
							<div key={"asdf"}>
								Kindly upload the transfer confirmation here or send to us via
								WhatsApp{" "}
								<Link
									className="font-bold text-blue"
									to={"https://wa.me/+2348068849845"}
								>
									wa.me/+2348068849845
								</Link>
							</div>
							,
						</div>
						<Button
							title="Done"
							className="!text-sm "
							onClick={() => {
								setCurrentScreen(Screens.fundingTransactionStatus);
								setDepositDetails({ ...depositDetails });
								// navigate("/funding-transaction-status", { state: depositDetails });
							}}
						/>
					</>
				) : (
					<Button
						title="Proceed with Paystack"
						className="!text-sm "
						onClick={() => null}
					/>
				)}
			</div>
		</CustomModal>
	);
};

export default DashiAccountDetails;
