import React from "react";
import CustomModal from "../../components/custom-modal/custom-modal";
import SectionTitle from "../create-ecard/section-title/section-title";
import { BankTransfer, Caution, Chevron, WalletFunding } from "../../assets/icons";
import { Button } from "../../components";
import { useNavigation } from "../../contexts";
import { Screens } from "../../enums";

export enum PaymentOptionEnum {
	InstantWalletFunding = "Instant Wallet Funding",
	BankTransfer = "Bank Transfer",
}
type PaymentOptions = { title: string; fee: string; icon: string; type: PaymentOptionEnum };

const PaymentOption = () => {
	const { setCurrentScreen, depositDetails, setDepositDetails } = useNavigation();
	console.log("🚀 ~ PaymentOption ~ depositDetails:", depositDetails);

	const paymentOptions: Array<PaymentOptions> = [
		{
			title: "Bank Transfer",
			fee: "0.00",
			icon: BankTransfer,
			type: PaymentOptionEnum.BankTransfer,
		},
		{
			title: "Instant Wallet Funding",
			fee: "100.00",
			icon: WalletFunding,
			type: PaymentOptionEnum.InstantWalletFunding,
		},
	];

	return (
		<CustomModal>
			<div className="flex flex-col items-center justify-center gap-[15px]">
				<SectionTitle title="Choose Payment Option" className="!text-base md:text-2xl" />
				<p className="text-sm md:text-base text-grey400 w-full max-w-[311px] text-center">
					Your payment will be processed within 1 - 2 business days
				</p>
			</div>

			<div className="flex flex-col gap-4 mt-[30px]">
				{paymentOptions.map((option: PaymentOptions, index: number) => (
					<div
						key={index}
						onClick={() => {
							setDepositDetails({ ...depositDetails, fundingType: option.type });
							setCurrentScreen(Screens.walletFunding);
						}}
						className="bg-grey100 rounded-[20px] p-5 w-full flex items-center justify-between h-[108px] cursor-pointer"
					>
						<div className="flex gap-3 h-[68px]">
							<div className="w-[64px] h-[64px] rounded-[10px] bg-white flex items-center justify-center">
								<img src={option.icon} alt="icon" />
							</div>

							<div className="flex flex-col items-start justify-between">
								<p className="text-black font-medium text-xs md:text-sm">
									{option.title}
								</p>
								<h4 className="font-bold text-black text-xl md:text-2xl">
									NGN {depositDetails?.amount}
								</h4>
								<p className="text-xs text-grey400">Fee: ₦{option.fee}</p>
							</div>
						</div>

						<img src={Chevron} alt="chevron" className="w-6 h-6 -rotate-90" />
					</div>
				))}
			</div>

			<div className="flex items-start justify-center gap-1 mt-[30px]">
				<img src={Caution} alt="icon" />
				<p className="text-grey400 text-sm md:text-base wifull max-w-[370px] text-center">
					Fund between 9:30am - 6:30pm for the fastest processing time
				</p>
			</div>

			<Button
				title="Cancel"
				className="!bg-transparent !text-red !text-sm"
				onClick={() => setCurrentScreen(null)}
			/>
		</CustomModal>
	);
};

export default PaymentOption;
